import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { 
  Button, 
  Box, 
  Typography, 
  Divider, 
  CircularProgress, 
  Snackbar, 
  Alert,
  Container,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00b09b',
    },
    secondary: {
      main: '#96c93d',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

const CheckoutForm = ({ amount, donorInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setError(null);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: donorInfo.name,
          email: donorInfo.email,
        },
      });

      if (error) {
        throw error;
      }

      console.log("PaymentMethod:", paymentMethod);
      // Handle the payment method further on the backend
      // For now, we'll just simulate a successful payment
      setTimeout(() => {
        setIsProcessing(false);
        navigate('/thank-you');
      }, 2000);

    } catch (err) {
      console.error(err);
      setError(err.message);
      setIsProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              borderRadius: 4,
              background: 'linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)',
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <LockIcon sx={{ fontSize: 32, color: "primary.main", mr: 1 }} />
                <Typography variant="h4" sx={{ fontWeight: 600, color: "primary.main" }}>
                  Secure Checkout
                </Typography>
              </Box>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 4, textAlign: "center" }}>
                Please enter your payment details below to complete your donation of ${amount}.
              </Typography>
              <Divider sx={{ width: "100%", mb: 4 }} />
              <Box sx={{ width: "100%", mb: 4 }}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </Box>
              <Button
                sx={{
                  mt: 2,
                  py: 1.5,
                  px: 4,
                  fontSize: "18px",
                  fontWeight: 600,
                  textTransform: "none",
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-1px)',
                    boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
                  },
                }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={!stripe || isProcessing}
              >
                {isProcessing ? <CircularProgress size={24} color="inherit" /> : `Pay $${amount}`}
              </Button>
            </Box>
          </Paper>
        </Container>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default CheckoutForm;