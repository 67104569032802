import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  TextField,
  Button,
  Paper,
  Container,
  CircularProgress,
  CardContent,
  Box,
  Card,
  Autocomplete,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { uploadVideo, updateVideo, fetchVideosTags } from "../APIS/videos";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";

export const categories = [
  { value: "prayer", label: "Prayer" },
  { value: "fasting", label: "Fasting" },
  { value: "charity", label: "Charity" },
  { value: "pilgrimage", label: "Pilgrimage" },
  { value: "belief", label: "Belief" },
  { value: "ethics", label: "Ethics" },
  { value: "purification", label: "Purification" },
  { value: "family", label: "Family" },
  { value: "companions", label: "Companions" },
  { value: "prophets", label: "Prophets" },
];

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(135deg, #e6ffe6 0%, #ffffff 50%, #f0f8f0 100%)",
  borderRadius: "15px",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  border: "1px solid rgba(255, 255, 255, 0.18)",
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#009900",
    },
    "&:hover fieldset": {
      borderColor: "#00cc00",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00cc00",
    },
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  "& .MuiInputLabel-root": {
    color: "#009900",
    "&.Mui-focused": {
      color: "#00cc00",
    },
  },
  "& .MuiInputBase-input": {
    color: "black", // Ensure the input text is black
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#009900",
  color: "#ffffff",
  width: 200,
  fontWeight: "bold",
  padding: "10px 20px",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: "#00cc00",
  },
  transition: "all 0.3s ease",
  boxShadow: "0 4px 6px rgba(0, 153, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#00cc00",
    transform: "translateY(-2px)",
    boxShadow: "0 6px 8px rgba(0, 153, 0, 0.2)",
  },
}));

const UploadArea = styled(Paper)(({ theme }) => ({
  borderRadius: "10px",
  border: "2px dashed #009900",
  backgroundColor: "rgba(240, 255, 240, 0.6)",
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(230, 255, 230, 0.8)",
    transform: "scale(1.02)",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    color: "#009900",
    fontWeight: "bold",
  },
}));
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.success.main,
  "&.Mui-checked": {
    color: theme.palette.success.main,
  },
}));

export const UploadVideo = ({ isEdit = false, video }) => {
  const navigate = useNavigate();

  const {
    data: fetchedCategories,
    categoriesLoading,
    error,
    refetch,
  } = useQuery("videoTags", fetchVideosTags);

  const [uploadedVideo, setUploadedVideo] = useState(
    video?.videos3link || null
  );
  const [uploadedThumbnail, setUploadedThumbnail] = useState(
    video?.thumbnails3link || null
  );
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: video?.name || "",

      category: video?.category
        ? { id: video?.category?.id, name: video?.category?.name }
        : null,
      file: video?.videos3link || null,
      thumbnail: video?.thumbnails3link || null,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .trim()
        .min(40, "Title must contain at least 40 characters")
        .required("Title is required"),
      category: Yup.object().nullable().required("Category is required"),
      file: Yup.mixed().required("Video file is required"),
      thumbnail: Yup.mixed().required("Thumbnail is required"),
    }),
    onSubmit: async (values) => {
      
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("video", formik.values.file);
        formData.append("thumbnail", formik.values.thumbnail);
        formData.append("name", values.title);
        formData.append(
          "category",
          values.category ? values.category.name : ""
        );

        if (isEdit) {
          const response = await updateVideo(formData, video?.id);
          if (response?.status === 200) {
            toast.success("Video Successfully Updated");
            navigate("/admin/videos");
          }
        } else {
          const response = await uploadVideo(formData);
          if (response?.status === 201) {
            toast.success("Video Successfully uploaded");
            navigate("/admin/videos");
          }
        }
      } catch (error) {
        console.error("Error uploading video:", error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
      formik.resetForm();
      setUploadedVideo(null);
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        formik.setFieldValue("file", acceptedFiles[0]);
        setUploadedVideo(URL.createObjectURL(acceptedFiles[0]));
      }
    },
  });

  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        formik.setFieldValue("thumbnail", acceptedFiles[0]);
        setUploadedThumbnail(URL.createObjectURL(acceptedFiles[0]));
      }
    },
  });

  return (
    <Container maxWidth="md">
      <StyledCard>
        <StyledCardContent>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              textAlign: "center",
              color: "#009900",
              marginBottom: 4,
            }}
          >
            {isEdit ? "Edit Video" : "Upload a Video"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              <StyledTextField
                fullWidth
                name="title"
                label="Title"
                variant="outlined"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              {categoriesLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Autocomplete
                  fullWidth
                  options={fetchedCategories || []}
                  getOptionLabel={(option) => option.name}
                  value={formik.values.category}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Select a tag"
                      required
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                    />
                  )}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("category", newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              )}

              <UploadArea {...getThumbnailRootProps()}>
                <input {...getThumbnailInputProps()} />
                <UploadFileIcon style={{ fontSize: 48, color: "#009900" }} />
                <Typography variant="body1" sx={{ color: "#009900", mt: 2 }}>
                  Drag or click here to {isEdit ? "edit" : "upload"} a thumbnail
                </Typography>
              </UploadArea>
              {formik.touched.thumbnail && formik.errors.thumbnail && (
                <Typography color="error">{formik.errors.thumbnail}</Typography>
              )}
              {uploadedThumbnail && (
                <img
                  src={uploadedThumbnail}
                  alt="Thumbnail Preview"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              )}

              <UploadArea {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadFileIcon style={{ fontSize: 48, color: "#009900" }} />
                <Typography variant="body1" sx={{ color: "#009900", mt: 2 }}>
                  Drag or click here to {isEdit ? "edit" : "upload"} a video
                </Typography>
              </UploadArea>
              {formik.touched.file && formik.errors.file && (
                <Typography color="error">{formik.errors.file}</Typography>
              )}
              {uploadedVideo && (
                <Box sx={{ width: "100%", position: "relative" }}>
                  <video
                    src={uploadedVideo}
                    controls
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <StyledButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress color="inherit" size={18} />
                    </Box>
                  ) : isEdit ? (
                    "Update"
                  ) : (
                    "Upload"
                  )}
                </StyledButton>
              </Box>
            </Box>
          </form>
        </StyledCardContent>
      </StyledCard>
    </Container>
  );
};
