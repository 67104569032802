import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ListTable } from "../../custom-components/ListTable";
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { deleteUser, getAllUsers } from "../../APIS/users";
import { useQuery } from "react-query";
import { WarningModal } from "../../custom-components/WarningModal";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { motion } from "framer-motion";
import { deleteVideoTag, fetchVideosTags } from "../../APIS/videos";

const MotionContainer = motion(Container);
const MotionTypography = motion(Typography);
const MotionButton = motion(Button);

export const TagsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [warningModal, setWarningModal] = React.useState(false);
  const [tagId, setTagId] = React.useState("");

  const { data: tags, isLoading, error, refetch } = useQuery("videoTags", fetchVideosTags);

  const handleCreateUser = () => {
    navigate("/admin/tags/create");
  };

  const handleWarningModal = (id) => {
    setWarningModal(true);
    setTagId(id);
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
  };

  const handleDelete = async () => {
    
    toast.loading("Deleting user...");
    try {
      const response = await deleteVideoTag(tagId);
      toast.dismiss();
      handleCloseWarningModal();
      toast.success("Tag has been successfully deleted");
      refetch();
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting tag: " + error.message);
    }
  };

  const userDataArray = tags?.map((record) => [
    
    { title: "Id", value: record.id },
    { title: "Tag", value: record.name },
  ]);

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      maxWidth="xl"
    >
      <Box
        sx={{
          py: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: `linear-gradient(45deg, ${theme.palette.success.light} 30%, ${theme.palette.success.main} 90%)`,
          borderRadius: "15px",
          padding: "20px",
          marginBottom: "20px",
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        }}
      >
        <MotionTypography
          variant="h3"
          fontWeight={700}
          initial={{ x: -50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            color: theme.palette.common.white,
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          Available Tags
        </MotionTypography>
        <MotionButton
          variant="contained"
          initial={{ x: 50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            borderRadius: "50px",
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            textTransform: "none",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: theme.palette.success.main,
              boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
            },
          }}
          onClick={handleCreateUser}
        >
          Add New Tag
        </MotionButton>
      </Box>
      <ListTable
        data={userDataArray}
        error="No users exist"
        isLoading={isLoading}
        onDelete={(record) => handleWarningModal(record[0]?.value)}
        permissions={{
          canDelete: true,
        }}
      />
      <WarningModal
        content="Are you sure you want to delete this user?"
        openModal={warningModal}
        handleClose={handleCloseWarningModal}
        handleDelete={handleDelete}
      />
    </MotionContainer>
  );
};
