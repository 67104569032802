import React, { useRef, useState } from "react";
import { CardComponent } from "./Card";
import {
  Box,
  Grid,
  Container,
  Typography,
  Chip,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
} from "@mui/material";
import { useSearch } from "./context/SearchContext";
import Skeleton from "@mui/material/Skeleton";
import { fetchLatestVideos, fetchVideos } from "./APIS/videos";
import { useQuery } from "react-query";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MotionGrid = motion(Grid);

export const greenGradient = "linear-gradient(45deg, #006400 30%, #008000 90%)";
export const yellowGradient =
  "linear-gradient(45deg, #FFD700 30%, #FFA500 90%)";
export const grayGradient = "linear-gradient(45deg, #808080 30%, #A9A9A9 90%)";

export const Home = () => {
  const { searchQuery } = useSearch();
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const [category, setCategory] = useState("Hadith");
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const { data: latestVideos, isLoading: isLoadingLatest } = useQuery(
    "latestVideos",
    () => fetchLatestVideos(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: videos, isLoading } = useQuery(
    ["videos", currentPage, itemsPerPage],
    () => fetchVideos(currentPage, itemsPerPage),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: currentPage !== undefined,
    }
  );

  const sortedVideos = videos?.videos;

  const filteredVideos = searchQuery
    ? sortedVideos?.filter((video) =>
        video?.name.toLowerCase().includes(searchQuery?.toLowerCase())
      )
    : sortedVideos || [];

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
    setCurrentPage(1);
  };

  const categories = [
    "All",
    "Quran",
    "Hadith",
    "Tafseer",
    "Islamic History",
    "Fiqh",
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const isLastPage = parseInt(videos?.pagination?.currentPage) == videos?.pagination?.totalPages;

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#FFFFFF", minHeight: "100vh" }}>
      {filteredVideos?.length > 0 && latestVideos?.length > 0 ? (
        <>
          <AppBar position="fixed" sx={{ background: greenGradient }}>
            <Toolbar>
              <IconButton
                edge="start"
                sx={{ color: "#FFFFFF" }}
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, fontWeight: "bold", color: "#FFFFFF" }}
              >
                IslamTube
              </Typography>
              <IconButton sx={{ color: "#FFFFFF" }}>
                <SearchIcon />
              </IconButton>
              <IconButton sx={{ color: "#FFFFFF" }}>
                <VideoCallIcon />
              </IconButton>
              <IconButton sx={{ color: "#FFFFFF" }}>
                <NotificationsIcon />
              </IconButton>
              <IconButton sx={{ color: "#FFFFFF" }}>
                <AccountCircleIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Toolbar />
          <Container maxWidth="xl" sx={{  pb: 4 }}>
            <Paper
              elevation={3}
              sx={{
                mb: 4,
                overflow: "hidden",
                borderRadius: 2,
                background: greenGradient,
                position: "relative",
              }}
            >
              <Slider ref={sliderRef} {...sliderSettings}>
                {latestVideos?.map((video) => (
                  <Box
                    key={video?.id}
                    sx={{ position: "relative", height: 400 }}
                    onClick={() => navigate(`/video/${video?.id}`)}
                  >
                    <img
                      src={video.thumbnails3link}
                      alt={video.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        p: 2,
                        background: "rgba(0,100,0,0.7)",
                      }}
                    >
                      <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                        {video.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
              <IconButton
                onClick={goToPrev}
                sx={{
                  position: "absolute",
                  left: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  bgcolor: "rgba(0,0,0,0.5)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "rgba(0,0,0,0.7)",
                  },
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                onClick={goToNext}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  bgcolor: "rgba(0,0,0,0.5)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "rgba(0,0,0,0.7)",
                  },
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Paper>
            <Box sx={{ overflowX: "auto", mb: 4 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                {categories.map((cat) => (
                  <Chip
                    key={cat}
                    label={cat}
                    clickable
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      transition: "all 0.3s",
                      background:
                        category === cat ? yellowGradient : grayGradient,
                      color: category === cat ? "#006400" : "#FFFFFF",
                    }}
                    onClick={() => handleCategoryChange(cat)}
                  />
                ))}
              </Box>
            </Box>
            <Typography
              variant="h4"
              sx={{ mb: 3, fontWeight: "bold", color: "#006400" }}
            >
              {searchQuery ? "Search Results" : "Recommended Videos"}
            </Typography>
            <Grid container spacing={3}>
              {isLoading
                ? [...Array(12)].map((_, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={200}
                        sx={{ borderRadius: 2, bgcolor: "rgba(0,100,0,0.1)" }}
                      />
                      <Box sx={{ pt: 1 }}>
                        <Skeleton
                          width="60%"
                          sx={{ bgcolor: "rgba(0,100,0,0.1)" }}
                        />
                        <Skeleton
                          width="40%"
                          sx={{ bgcolor: "rgba(0,100,0,0.1)" }}
                        />
                      </Box>
                    </Grid>
                  ))
                : filteredVideos?.map((video, index) => (
                    <MotionGrid
                      item
                      key={video?.id}
                      xs={12}
                      sm={6}
                      md={3}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <CardComponent module={video} />
                    </MotionGrid>
                  ))}
            </Grid>
            {!isLoading && filteredVideos?.length === 0 && (
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography variant="h5" sx={{ color: "#006400" }}>
                  No videos found
                </Typography>
              </Box>
            )}
            {!isLoading && filteredVideos?.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                  variant="contained"
                  sx={{
                    fontWeight: "bold",
                    borderRadius: 2,
                    transition: "all 0.3s",
                    background: yellowGradient,
                    color: "#006400",
                    "&:hover": {
                      background:
                        "linear-gradient(45deg, #FFA500 30%, #FFD700 90%)",
                    },
                    "&:disabled": {
                      background: grayGradient,
                      color: "#FFFFFF",
                    },
                  }}
                  disabled={isLastPage}
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                  {isLastPage ? "No More Videos" : "Load More"}
                </Button>
              </Box>
            )}
          </Container>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h3">No Videos Found</Typography>
        </Box>
      )}
    </Box>
  );
};

