import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ListTable } from "../../custom-components/ListTable";
import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import { WarningModal } from "../../custom-components/WarningModal";
import { toast } from "react-toastify";
import moment from "moment";
import {
  fetchVideosForAdmin,
  deleteVideo,
  fetchVideos,
  fetchVideosByRole,
} from "../../APIS/videos";
import { VideoDetailModal } from "./videoDetailModal";
import { motion } from "framer-motion";
import { truncateString } from "../../custom-components/TruncateString";
import { getPermissions, getRole } from "../../layout/Sidebar";

const MotionContainer = motion(Container);
const MotionTypography = motion(Typography);
const MotionButton = motion(Button);

export const Videos = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const role = getRole();
  const permissions = getPermissions()?.map((permission) => permission.name);

  console.log(permissions, role?.name);
  const [warningModal, setWarningModal] = React.useState(false);
  const [detailsModal, setDetailsModal] = React.useState(false);
  const [videoId, setVideoId] = React.useState("");
  const [video, setVideo] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const isStudent = role?.name === "Student";
  const isTeacher = role?.name === "Teacher";
  const isDataEntry = role?.name === "DataEntry";
  const isSuperAdmin = role?.name === "SuperAdmin";


  const {
    data: videos,
    isLoading,
    refetch,
  } = useQuery(
    ["videos", currentPage + 1, rowsPerPage],
    () => {
      if (isSuperAdmin) {
        return fetchVideosForAdmin(currentPage + 1, rowsPerPage);
      } else {
        return fetchVideosByRole(currentPage + 1, rowsPerPage);
      }
    },

    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleWarningModal = (id) => {
    setWarningModal(true);
    setVideoId(id);
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
  };

  const handleCloseDetailsModal = () => {
    setDetailsModal(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleDelete = async () => {
    toast.loading("Deleting video...");
    try {
      await deleteVideo(videoId);
      toast.dismiss();
      toast.success("Video has been successfully deleted");
      handleCloseWarningModal();
      refetch();
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting video: " + error.message);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/edit/${id}`);
  };

  const handleView = (id) => {
    const fullVideoObject = videos?.videos?.find((video) => video?.id === id);

    setVideo(fullVideoObject);
    setDetailsModal(true);
  };

  const userDataArray = videos?.videos?.map((record) => [
    { title: "Id", value: record?.id || "-" },
    { title: "Name", value: truncateString(record?.name, 40) || "-" },
    { title: "Tag", value: record?.category || "-" },
    { title: "status", value: record?.verificationStatus ? "verified" : "pending" || "-" },
    ...(isStudent || isTeacher
      ? []
      : [
          {
            title: "Uploaded By",
            value: record?.uploadedbyUser
              ? `${record?.uploadedbyUser?.firstName || ""} ${
                  record?.uploadedbyUser?.lastName || ""
                }`.trim() || "-"
              : "-",
          },
          {
            title: "Uploader Email",
            value: record?.uploadedbyUser?.email || "-",
          },
          {
            title: "Approved By",
            value: record?.approvedbyUser
              ? `${record?.approvedbyUser?.firstName || ""} ${
                  record?.approvedbyUser?.lastName || ""
                }`.trim() || "-"
              : "-",
          },
          {
            title: "Approver Email",
            value: record?.approvedbyUser?.email || "-",
          },
        ]),
    {
      title: "Uploaded at",
      value: record?.createdat
        ? moment(record.createdat).format("MMM Do YY")
        : "-",
    },
  ]);

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      maxWidth="xl"
    >
      <Box
        sx={{
          py: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: `linear-gradient(45deg, ${theme.palette.success.light} 30%, ${theme.palette.success.main} 90%)`,
          borderRadius: "15px",
          padding: "20px",
          marginBottom: "20px",
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        }}
      >
        <MotionTypography
          variant="h3"
          fontWeight={700}
          initial={{ x: -50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            color: theme.palette.common.white,
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          All Videos
        </MotionTypography>
        {!isStudent && !isTeacher && (
          <MotionButton
            variant="contained"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 120 }}
            onClick={() => navigate("create")}
            sx={{
              borderRadius: "50px",
              color: theme.palette.common.white,
              backgroundColor: theme.palette.success.dark,
              textTransform: "none",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.success.main,
                boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
              },
            }}
          >
            Add New Video
          </MotionButton>
        )}
      </Box>
      <ListTable
        data={userDataArray}
        error="No Videos Found"
        isLoading={isLoading}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        totalItems={videos?.pagination?.totalRecords || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onEdit={(record) => handleEdit(record[0].value)}
        onDelete={(record) => handleWarningModal(record[0].value)}
        onView={(record) => handleView(record[0].value)}
        permissions={{
          canEdit:
            (permissions?.includes("videe_editing") && !isStudent) ||
            !isTeacher,
          canDelete:
            permissions?.includes("video_delete") &&
            role?.name === "SuperAdmin",
          canView:
            role?.name === "admin" ||
            isStudent ||
            isTeacher ||
            isDataEntry||
            role?.name === "SuperAdmin",
        }}
      />
      <WarningModal
        content="Are you sure you want to delete this video?"
        openModal={warningModal}
        handleClose={handleCloseWarningModal}
        handleDelete={handleDelete}
      />
      <VideoDetailModal
        video={video}
        openModal={detailsModal}
        handleClose={handleCloseDetailsModal}
        refetch={refetch}
      />
    </MotionContainer>
  );
};
